




































import { Component, Prop, Vue } from "vue-property-decorator";
import { CIcon } from "@/models/utils/c-icon.interface";

@Component
export default class CosmopolLabel extends Vue {
  @Prop({ required: false, default: "--", type: String })
  private label: string;
  @Prop({ required: false, default: null, type: Object })
  private icon: Pick<CIcon, "name" | "fill">;
  @Prop({ required: false, default: false, type: Boolean })
  private iconClickable: boolean;
}
